<template>
    <v-container fluid>

        <v-card>
            <v-img
                class="white--text align-top"
                height="200px"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
                :src="`/images/Referencias.jpg`"
            >
                <v-card-title justify-top>
                    Referencias
                </v-card-title>
            </v-img>

            <v-card-text>
                <v-row no-gutters>
                    <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                        <v-sheet class="d-flex flex-column justify-center" elevation="2">
                            <v-date-picker
                                v-model="pickerFecha"
                                :max="estaFecha"
                                full-width
                                no-title
                                locale="es-mx"
                                scrollable
                            ></v-date-picker>

                            <v-btn text color="primary" @click="setHoy">
                                HOY
                            </v-btn>

                        </v-sheet>
                    </v-col>
                    <v-col xs="12" sm="12" md="8" lg="8" xl="8" cols="12">
                        <v-toolbar dark color="primary" dense>
                            <v-toolbar-title>Buscar referencias</v-toolbar-title>

                            <v-spacer/>
                            <v-btn @click="$router.push({ path: '/referencias/nueva' })" icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-form
                            ref="buscaReferencias"
                        >
                            <v-row no-gutters>

                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">

                                    <v-radio-group
                                        class="px-4"
                                        v-model="busca.tipoRef"
                                        filled
                                        rounded
                                        row
                                    >
                                        <v-radio label="Recibida" value="r"></v-radio>
                                        <v-radio label="Enviada" value="e"></v-radio>
                                    </v-radio-group>

                                </v-col>

                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <select-page
                                        :registrosMax='maxUnidad'
                                        :total="totalUnidad"
                                        :query='queryUnidad'
                                        :page='uniPage'
                                        @carga='cargaUnidad'
                                    >
                                        <template v-slot="{dat}">
                                            <v-autocomplete
                                                v-model="busca.unidad"
                                                class="px-4"
                                                :items="itemsUnidad"
                                                :loading="loadingUnidad"
                                                label="Unidad que refiere"
                                                :search-input.sync="queryUnidad"
                                                item-text="clues"
                                                item-value="id"
                                                no-filter
                                                clearable
                                                append-icon=''
                                            >
                                                <template v-slot:item="dat">
                                                    <div class="d-flex flex-column pb-2">
                                                        <div class="font-weight-bold text-body-2">{{dat.item.clues}}-{{dat.item.nombre}}</div>
                                                        <div class="text-caption">Unidad: {{dat.item.tipoInstitucion}} - {{dat.item.dependencia}}</div>
                                                        <div class="text-caption">{{dat.item.municipio}} {{dat.item.estado}}</div>
                                                    </div>
                                                </template>

                                                <template v-slot:append-item>
                                                    <v-pagination
                                                        v-model="uniPage"
                                                        circle
                                                        :length="dat.pageLength"
                                                        total-visible="7"
                                                    ></v-pagination>
                                                </template>
                                            </v-autocomplete>
                                        </template>
                                    </select-page>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="busca.paciente"
                                        class="px-4"
                                        clearable
                                        label="Paciente"
                                    />
                                </v-col>



                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <v-select
                                        v-model="busca.tipo"
                                        class="px-4"
                                        clearable
                                        :items="$store.state.catalogos.tiposReferencias"
                                        label="Tipo"
                                    />
                                </v-col>

                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <v-select
                                        v-model="busca.servicio"
                                        class="px-4"
                                        clearable
                                        :items="$store.state.catalogos.especialidades"
                                        item-text="especialidad"
                                        item-value="id"
                                        label="Servicio"
                                        @focus="$store.dispatch('catalogos/cargaEspecialidad')"
                                    />
                                </v-col>

                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <v-select
                                        v-model="busca.status"
                                        class="px-4"
                                        clearable
                                        :items="$store.state.catalogos.estadosReferencias"
                                        label="Estado"
                                    />
                                </v-col>

                                <v-col xs="12" sm="12" md="6" lg="6" xl="6" cols="12">
                                    <v-text-field
                                        v-model="busca.folio"
                                        class="px-4"
                                        clearable
                                        label="Folio"
                                    />
                                </v-col>


                            </v-row>

                            <div class="d-flex px-4 justify-end">
                                <v-btn color="error" @click="limpia">
                                    Limpiar
                                </v-btn>
                                <v-btn text color="success" @click="filtra">
                                    Filtrar
                                </v-btn>
                            </div>
                        </v-form>
                    </v-col>

                    <v-col cols="12" class="pt-3">
                        <v-data-table
                            elevation="2"
                            :headers="headers"
                            :items="referencias"
                            disable-pagination
                            disable-filtering
                            disable-sort
                            hide-default-footer
                            :loading="loadingRefs"
                        >

                            <!--  El folio  -->
                            <template v-slot:[`item.folio`]="{ item }">
                                <v-icon color="orange" v-if="item.tipoRef == 'e'">
                                    mdi-email-send-outline
                                </v-icon>
                                <v-icon color="green" v-if="item.tipoRef == 'r'">
                                    mdi-email-receive-outline
                                </v-icon>
                                {{item.folio}}
                            </template>

                            <!--  La fecha de solicitud  -->
                            <template v-slot:[`item.fechaSolicitud`]="{ item }">
                                <v-chip
                                    class="ma-2 white--text"
                                    :color="$store.state.catalogos.estadosReferencias.find(x=>x.value == item.status).color"
                                >
                                    <span class="text-caption">{{$store.state.catalogos.estadosReferencias.find(x=>x.value == item.status).text}}</span>
                                </v-chip><br/>
                                {{item.fechaSolicitud}}
                            </template>

                            <!--  La fecha de solicitud  -->
                            <template v-slot:[`item.unidad`]="{ item }">
                                ({{item.unidadClues}}) {{item.unidadNombre}}
                            </template>

                            <!--  Tipo de solicitud  -->
                            <template v-slot:[`item.tipo`]="{ item }">
                                {{$store.state.catalogos.tiposReferencias.find(x=>x.value == item.tipo).text}}
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    class="mx-1"
                                    @click="$router.push('/referencias/ref/'+item.folio)"
                                >
                                    mdi-magnify
                                </v-icon>
                            </template>
                        </v-data-table>
                        <v-pagination
                            circle
                            v-model="pageRefs"
                            :length="pageLengthRefs"
                            total-visible="7"
                            @input="cargaRefs"
                        ></v-pagination>

                        <div class="text-center caption">
                            <b>{{pageRefsDe}}</b> a <b>{{pageRefsA}}</b> de <b>{{totalRefs}}</b>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>


        </v-card>
    </v-container>
</template>

<script>
import SelectPage from '@/components/com/SelectPage'
export default {
    components:{
        'select-page':SelectPage,
    },
    async mounted(){
        //this.pickerFecha = this.moment(new Date()).format('YYYY-MM-DD')
        this.pageRefs=1
        
        this.cargaRefs()
    },
    data:()=>({
        fechaReferencia:'',
        pickerFecha:'',
        loadingRefs:false,
        //pageRefsLength:5,

        headers:[
            {text:'Folio', value:'folio', align:'center', width:'10%'},
            {text:'Solicitado el', value:'fechaSolicitud', width:'15%', align:'center'},
            {text:'Paciente', value:'paciente', width:'25%'},
            {text:'Unidad que refiere', value:'unidad', width:'35%'},
            {text:'Tipo de referencia', value:'tipo', width:'10%', align:'center',},
            {text:'', value:'actions', width:'5%', align:'center',},
        ],
        referencias:[],

        busca:{

        },

        // Para seleccionar la unidad
        // Para seleccionar al personal
        queryUnidad:'',
        itemsUnidad:[],
        loadingUnidad:false,
        totalUnidad:0,
        maxUnidad:10,
        uniPage:1,

        totalRefs:0,
        limitRefs:10,
        pageRefs:1,
    }),
    methods:{
        

        // Carga las unidades que refieren
        async cargaUnidad(page, query, start){
            //this.itemsUnidad=[]
            if (!this.loadingUnidad && this.queryUnidad.length>=3){
                this.loadingUnidad = true
                
                try{
                    let consulta = await this.$http({
                        url:'/catalogos/hospitalesSimple',
                        method:'GET',
                        params:{
                            page:page,
                            query:this.queryUnidad,
                            start:start,
                            limit:this.maxUnidad,
                        },
                    })
                    this.itemsUnidad=consulta.data.root
                    this.totalUnidad = consulta.data.total
                    this.loadingUnidad = false
                    //this.uniPage = 1
                }catch(err){
                    this.loadingUnidad = false
                    this.$store.dispatch('security/revisaError',err)
                }
            }
        },
        // Cargando las referencias
        async cargaRefs(){
            //console.log('cargando')
            this.loadingRefs=true
            try{
                let params = {
                    limit: this.limitRefs,
                    start: (this.pageRefs -1) * this.limitRefs,
                    ...this.busca
                }
                let rf = await this.$http({
                    url:'/referencias/list',
                    method:'GET',
                    params:params,
                })
                this.loadingRefs=false
                this.totalRefs = rf.data.total
                this.referencias = rf.data.root
            }catch(err){
                this.loadingRefs=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        // Carga los registros basándose en los parámetros de búsqueda
        filtra(){
            this.cargaRefs()
        },

        // Limpia parámetros de búsqueda y carga todos los eregistros
        limpia(){
            this.busca = {}
            this.$refs.buscaReferencias.reset()
            this.pickerFecha=''
            this.cargaRefs()
        },
        // Carga la fecha de hoy
        setHoy(){
            this.pickerFecha=this.moment(new Date()).format('YYYY-MM-DD')
            
        },
        
    },
    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },

        /* -----------------
        Computados para la paginación
        -------------------- */
        pageLengthRefs: function(){
            return (Math.ceil(this.totalRefs / this.limitRefs));
        },
        pageRefsDe: function(){
            let pd = 0
            
            if (this.totalRefs > 0) {
                pd = ((this.pageRefs - 1) * this.limitRefs)+1
            }
            
            return pd
            
        },
        pageRefsA: function(){
            let pa = this.pageRefs * this.limitRefs
            
            if (pa > this.totalRefs ){
                pa = this.totalRefs
            }
            return (pa)
        },
        /* -------------------------- */

    },
    watch:{
        pickerFecha(v){
            if (v){
                let fe = v.split('-')
                //this.fechaReferencia = fe[2]+'-'+fe[1]+'-'+fe[0]
                this.busca.fechaDe = fe[2]+'/'+fe[1]+'/'+fe[0]
            } else {
                this.busca.fechaDe = null
            }
        },
    },
}
</script>

<style>

</style>